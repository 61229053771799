import $ from "jquery";

import Tab from "DMS/Seed/Seed.Tab";

class Menu {
	/**
	 * Afficher le module
	 *
	 * @param pModuleName
	 *            Nom du module a afficher
	 * @param pTitre
	 *            Titre de l'onglet
	 */
	ShowModule(pModuleName : string, pTitre : string, isDefaultAction : boolean) {
		// Noter le module actif comme ouvert
		$('#tdmainmenu>li.Seed_Menu_Active').addClass('Seed_Menu_Opened');
		// Cacher les modules
		$('#tdmainmenu > li').removeClass("Seed_Menu_Active active");
		// Afficher le module activé
		$("#" + pModuleName + "_link").addClass("Seed_Menu_Active active Seed_Menu_Opened");

		Tab.ShowModule(pModuleName, pTitre, isDefaultAction);
	}

	/**
	 * Supprimer le module à l'affichage
	 *
	 * @param pModuleName Nom du module à supprimer
	 */
	Clear(pModuleName : string) {
		while ($('#' + pModuleName + '_tab').length !== 0) 
			$('#' + pModuleName + '_tab').remove();

		$("#" + pModuleName + "_frame").remove();
		Tab.aActive[pModuleName] = null;
	}
};

export default new Menu();
