import Ajax from "DMS/Seed/Seed.Ajax";
import Tab from "DMS/Seed/Seed.Tab";
import { decodeParam } from "DMS/Utils/parameter";
import { resizeScreen } from "DMS/Utils/resizeScreen";

class Page {
	Load(Page : string, pImport : any, newTab : boolean) {
		let sImport = "";

		if (pImport) {
			let length = pImport.length;
			for (let i = 0; i < length; i++) {
				pImport[i][1] = decodeParam(pImport[i][1]);
				sImport += "&" + pImport[i][0] + "=" + pImport[i][1];
			}
		}

		return Ajax.Post({
			url: Page,
			data: sImport,
			json: true
		}).then((xml) => {
			if (newTab) {
				Tab.GetFrame().html(xml.html);
				let jscript : any = document.createElement("SCRIPT");
				jscript.text = xml.script;
				Tab.GetFrame().append(jscript);
				resizeScreen();
			}
		});
	}
};

export default new Page();