import { Observable } from 'rxjs';

enum AjaxType {
	XML = "xml",
	JSON = "json",
	TEXT = "text"
}

class AjaxParameters {
	method: string;
	url: string;
	success: Function;
	type: AjaxType;
	body: any;
	headers: any;

	constructor(method = "GET", url = "", headers = {}, data = {}, type = AjaxType.JSON, success = () => { }) {
		this.method = method;
		this.body = data;
		this.url = url;
		this.success = success;
		this.type = type;
		this.headers = headers;
	}
}

class Ajax {

	async InternalAjax(ajaxParameters: any) {
		let loaderId = this.startDelayedLoading();
		let params: any = {
			method: ajaxParameters.method,
			headers: ajaxParameters.headers
		};

		if (ajaxParameters.method !== "GET" && ajaxParameters.method !== "HEAD")
			params.body = ajaxParameters.body;

		let fetchPromise = fetch(ajaxParameters.url, params).then((response) => {
			if (!response.ok)
				return Promise.reject(response.statusText);

			if (ajaxParameters.type === AjaxType.XML) {
				console.warn("Appel ajax en XML", ajaxParameters.url);
				let domParser = new DOMParser();
				return response.text().then((textData) => domParser.parseFromString(textData, "text/xml"));
			} else if (ajaxParameters.type === AjaxType.JSON)
				return response.json();
			else {
				console.warn("Appel ajax texte brut", ajaxParameters.url);
				return response.text();
			}
		}).catch((err) => {
			console.error(ajaxParameters.url, ajaxParameters.body);
			console.error(err);
			return Promise.reject(err);
		}).finally(() => this.stopDelayedLoading(loaderId));

		if (ajaxParameters.success !== null)
			fetchPromise.then(ajaxParameters.success);

		return fetchPromise;
	}

	async CommonAjax(method: string, Param: any) {
		let localData: any = Param["data"];

		if(Param["postJson"]) {
			localData = JSON.stringify(localData);
		} else if (typeof localData === 'string') {
			console.warn("Ajax send Url Encoded");
			localData = this.decodeParams(localData);
		} else if (typeof localData === 'object') {
			if (!(localData instanceof FormData)) {
				localData = this.formDataNested(localData);
			}
		}

		let type = Param["json"] ? AjaxType.JSON : (Param["xml"] ? AjaxType.XML : AjaxType.TEXT);
		let headers = Param["postJson"] ? { 'Content-Type': 'application/json' } : {};
		let ajaxParameters = new AjaxParameters(method, Param["url"], headers, localData, type, Param["success"]);
		return this.InternalAjax(ajaxParameters);
	}

	async Post(Param: any) {
		return this.CommonAjax("POST", Param);
	}

	async Get(Param: any) {
		return this.CommonAjax("GET", Param);
	}

	PostAngular(url : string, data : any = {}) : Observable<Object> {
		return new Observable(observer => {
			this.Post({
				url: url,
				json: true,
				data: data,
				async: true,
				postJson: true
			}).then((json) => {
				observer.next(json);
				observer.complete();
			})
		});
	}

	loadingSucceed() {
		//this.loadingStop();
	}

	loadingStop() {
		/*document.querySelector("#load").style.display = "none";
		document.body.style.cursor = "";*/
	}

	loadingStart() {
		/*document.querySelector("#load #message").textContent = "Traitement en cours..."; // @todo : lexique
		document.querySelector("#load").style.display = "block";
		// $("#load").fadeIn('fast');
		document.body.style.cursor = "wait";*/
	}

	loadingFailed() {
		//this.loadingStop();
	}

	//Arrete et demarre timer
	startDelayedLoading() {
		/*let loaderId = setTimeout(this.loadingStart, 300);
		setTimeout(this.loadingStop, 1000);
		return loaderId;*/
	}

	//Arrete le timer
	stopDelayedLoading(loaderId: any) {
		/*clearTimeout(loaderId);
		this.loadingStop();*/
	}

	decodeParams(params: any) {
		return new URLSearchParams(params);
	}

	formData(object: any) {
		return Object.keys(object).reduce((formData, key) => {
			formData.append(key, object[key]);
			return formData;
		}, new FormData());
	}

	formDataNested(obj: any, form: FormData = null, namespace: string = "") {

		let fd = form || new FormData();
		let formKey: string;

		for (let property in obj) {
			if (obj.hasOwnProperty(property)) {

				if (namespace != "") {
					formKey = namespace + '[' + property + ']';
				} else {
					formKey = property;
				}

				if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
					this.formDataNested(obj[property], fd, formKey);
				} else {
					fd.append(formKey, obj[property]);
				}
			}
		}

		return fd;
	}
}

export default new Ajax();