import $ from "jquery";
import bootbox from "bootbox";

import Ajax from "DMS/Seed/Seed.Ajax";
import { encodeParam } from "DMS/Utils/parameter";
import { resizeScreen } from "DMS/Utils/resizeScreen";
import { empty, trim } from "DMS/Utils/lib";

declare let root : String;
declare let moduleType : String;
declare let MSG_SECTORS_LINKS_TO_OTHER_AGENCIES : String;
declare let CMD_CANCEL : String;
declare let CMD_SECTORS_DELETE_LINKS : String;
declare let MSG_SECTORS_LINKS_TO_NON_SELECTED_AGENCIES : String;
declare let MSG_SECTORS_LINKS_TO_NON_SELECTED : String;
declare let MSG_NUM_FACTURE_EXISTS : String;

class Form {
	/**
	 * Appelle un formulaire
	 *
	 * @param numcontent
	 * @param pTargetId Id de l'element DomDocument où ajouter le formulaire
	 * @param numAction n° de l'action
	 * @param bbmIndex clef interne
	 * @param pImport
	 * @param setTabText booleen : mettre à jour le texte de l'onglet courant ? false si le formulaire est dans une popup
	 */
	requestFormulaire(numcontent : string, pTargetId : string, numAction : string, bbmIndex : string, pImport : string, setTabText = true) {
		let sImport = "";
		if (pImport) {
			let pImportLength = pImport.length;
			for (var i = 0; i < pImportLength; i++) {
				sImport += "&" + pImport[i][0] + "=" + encodeParam(pImport[i][1]);
			}
		}

		let numcontentform = 0;
		numcontentform = eval(numcontent) + 1;
		Ajax.Post({
			url: root + "_module/ListAndForm/FormCore.php",
			data: "valeurCle=" + bbmIndex
				+ "&numcontent=" + numcontentform
				+ "&numaction=" + numAction
				+ sImport
				+ "&moduleType=" + moduleType,
			json: true,
			async: true
		}).then((xml) => {
			let pTarget = document.getElementById(pTargetId);
			$(pTarget).html(xml.html);
			let jscript = document.createElement("SCRIPT") as HTMLScriptElement; // BALISE SCRIPT
			jscript.text = 'var pTargetId = "' + pTargetId + '";var setTabText = ' + setTabText + ';' + xml.jscript;
			pTarget.appendChild(jscript); // AJOUT DU SCRIPT AU DOCUMENT

			// Redimensionnement de la grille
			resizeScreen();
		});
	}

	// updating a select dom element (second argument) :
	// its selectable values are the values selected in the multiselect dom element (first argument).
	// After the update, the previously selected value of the select dom element is still the selected one
	// if it is also still selected in the multiselect dom element
	updateDefaultSelect(multiselect_domElement : HTMLSelectElement, select_domElement : HTMLSelectElement){
		let $multiselectOptions = $(multiselect_domElement).children('option:selected');
		let $select_domElement = $(select_domElement);
		let selectedValue = $select_domElement.val();

		$select_domElement.empty();
		// first empty value
		$('<option></option>').appendTo($select_domElement);
		
		$multiselectOptions.each( function(index, element) {
			let $currentMultiselectOption = $(element);
			let currentMultiselectOptionValue = $currentMultiselectOption.attr('value');
			let currentMultiselectOptionText = $currentMultiselectOption.text();
			let currentOptionJqueryObj = $('<option></option>').appendTo($select_domElement);
			currentOptionJqueryObj.attr('value', currentMultiselectOptionValue).text(currentMultiselectOptionText);
			if (currentMultiselectOptionValue === selectedValue) {
				currentOptionJqueryObj.prop('selected', true);
			}
		});
	}

	async updateSectorsForTechnician(technicien_bbmindex : string, secteur_array : any, multiselect_domElement : HTMLSelectElement, select_domElement : HTMLSelectElement) {
		return Ajax.Post({
			url: '_module/ListAndForm/ajax/updateSectorsForTechnician.php',
			data: 'technicien_bbmindex=' + technicien_bbmindex
				+ '&secteur_array=' + JSON.stringify(secteur_array)
		}).then(() => {
			this.updateDefaultSelect(multiselect_domElement, select_domElement);
		});
	}

	updadeAndRebuildMultiselect(textAndValueArray : Array<any>, multiselect_domElement : HTMLSelectElement){
		let multiselectJqueryObj = $(multiselect_domElement);
		multiselectJqueryObj.empty();
		$.each(textAndValueArray, (index, option) => {
			let currentOptionJqueryObj = $('<option></option>').appendTo(multiselectJqueryObj);
			currentOptionJqueryObj.attr('value', option.value).text(option.text);
			if(option.selected){
				currentOptionJqueryObj.prop('selected', true);
			}
		});

		(<any>multiselectJqueryObj).multiselect('rebuild');
	}

	async checkLinkedSectorsForTechnician(technicien_bbmindex : string, codeagence : string, agence_domElement : HTMLSelectElement, secteur_multiselect_domElement : HTMLSelectElement, secteur_default_domElement : HTMLSelectElement, force_delete : boolean = false) {
		return Ajax.Post({
			url: '_module/ListAndForm/ajax/checkLinkedSectorsForTechnician.php',
			data: 'technicien_bbmindex=' + technicien_bbmindex
				+ '&codeagence=' + codeagence
				+ '&force_delete=' + force_delete,
			json: true,
		}).then((responseJson) => {
			if(force_delete || responseJson.old_xagence_links === '0') {
				this.updadeAndRebuildMultiselect(responseJson.new_secteur_array, secteur_multiselect_domElement);
			} else {
				bootbox.dialog({ 
					message: MSG_SECTORS_LINKS_TO_OTHER_AGENCIES,
					buttons: {
						cancel: {
							label: CMD_CANCEL,
							callback: () => {
								agence_domElement.value = responseJson.codeagence_old;
							}
						},
						taskOnly: {
							label: CMD_SECTORS_DELETE_LINKS,
							callback: () => {
								this.checkLinkedSectorsForTechnician(technicien_bbmindex, codeagence, agence_domElement, secteur_multiselect_domElement, secteur_default_domElement, true);
							}
						}
					}
				});
			}
		}).then(() => {
			this.updateDefaultSelect(secteur_multiselect_domElement, secteur_default_domElement);
		});
	}

	async checkLinkedSectorsForAgency(agence_bbmindex : string, secteur_array : any, secteur_multiselect_domElement : HTMLSelectElement, force_delete : boolean = false) {
		return Ajax.Post({
			url: '_module/ListAndForm/ajax/checkLinkedSectorsForAgency.php',
			data: 'agence_bbmindex=' + agence_bbmindex
				+ '&secteur_array=' + JSON.stringify(secteur_array)
				+ '&force_delete=' + force_delete,
			json: true
		}).then((responseJson) => {
			if(!force_delete && responseJson.other_xsecteur_links !== '0') {
				bootbox.dialog({ 
					message: MSG_SECTORS_LINKS_TO_NON_SELECTED,
					buttons: {
						cancel: {
							label: CMD_CANCEL,
							callback: () => {
								this.updadeAndRebuildMultiselect(responseJson.old_secteur_array, secteur_multiselect_domElement);
							}
						},
						taskOnly: {
							label: CMD_SECTORS_DELETE_LINKS,
							callback: () => {
								this.checkLinkedSectorsForAgency(agence_bbmindex, secteur_array, secteur_multiselect_domElement, true);
							}
						}
					}
				});
			}
		});
	}

	async checkLinkedSectorsForSector(secteur_bbmindex : string, agence_array : any, agence_multiselect_domElement : HTMLSelectElement, technicien_multiselect_domElement : HTMLSelectElement, force_delete : boolean = false) {
		return Ajax.Post({
			url: '_module/ListAndForm/ajax/checkLinkedSectorsForSector.php',
			data: 'secteur_bbmindex=' + secteur_bbmindex
				+ '&agence_array=' + JSON.stringify(agence_array)
				+ '&force_delete=' + force_delete,
			json: true
		}).then((responseJson) => {
			if(force_delete || responseJson.other_xagence_links === '0') {
				this.updadeAndRebuildMultiselect(responseJson.new_technicien_array, technicien_multiselect_domElement);
			} else{
				bootbox.dialog({ 
					message: MSG_SECTORS_LINKS_TO_NON_SELECTED_AGENCIES,
					buttons: {
						cancel: {
							label: CMD_CANCEL,
							callback: () => {
								this.updadeAndRebuildMultiselect(responseJson.old_agence_array, agence_multiselect_domElement);
							}
						},
						taskOnly: {
							label: CMD_SECTORS_DELETE_LINKS,
							callback: () => {
								this.checkLinkedSectorsForSector(secteur_bbmindex, agence_array, agence_multiselect_domElement, technicien_multiselect_domElement, true);
							}
						}
					}
				});
			}
		});
	}

	isSameGroup(group : string, current_group : string) : boolean {
		group = trim(group);

		if(empty(group))
			return false;

		let authorizedGroup = group.split(',');
		authorizedGroup.map((elem) => trim(elem));

		for(let uniqueGroup of authorizedGroup) {
			if(uniqueGroup.localeCompare(current_group, undefined, { sensitivity: 'accent' }) === 0 || uniqueGroup.localeCompare("tous", undefined, { sensitivity: 'accent' }) === 0) 
				return true;
		}

		return false;
	}

	async updateNumFacture(bbmindex : string, codefournisseur_domElement : HTMLSelectElement, numfacture_domElement : HTMLInputElement)
	{		
		return Ajax.Post({
			url: '_module/ListAndForm/ajax/updateNumFacture.php',
			data: 'bbmindex=' + bbmindex
				+ '&codefournisseur=' + codefournisseur_domElement.value
				+ '&numfacture=' + numfacture_domElement.value,
			json: true
		}).then((responseJson) => {
			if(responseJson.do_numfacture_exist !== '0') {
				bootbox.alert(MSG_NUM_FACTURE_EXISTS);
				codefournisseur_domElement.value = responseJson.codefournisseur_old;
				numfacture_domElement.value = responseJson.numfacture_old;
			}
		});
	}
};

export default new Form();